@import url('https://cdn.syncfusion.com/ej2/material.css');

.responsive-div {
  height: 100vh;
  width: 50%; /* Ancho por defecto (para pantallas grandes) */
  color: var(--dark-text-gray-200);
  background-color: var(--background-color, white); /* Soporte para temas */
  padding: 16px;
  overflow-y: auto;
  border-left: 1px solid #ddd;
  box-shadow: -4px 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 0; /* Sin bordes redondeados */
  display: flex;
  flex-direction: column;
}


.sidebar {
  box-shadow: rgb(113 122 131 / 11%) 0px 7px 30px 0px;
}


.nav-item,
.navbar {
  z-index: 10000;
}

.img-logo {
  filter: none; /* Sin filtro para el modo claro */
}

/* Estilo para modo oscuro */
.dark .img-logo {
  filter: invert(1); /* Invierte los colores para el modo oscuro */
}

.e-dlg-center-center, .e-quick-popup-wrapper.e-device{
  z-index: 1000000 !important;
}

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(216, 216, 216);
  border-radius: 40px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* color-picker style  */

 #preview {
  background: transparent
    url('https://ej2.syncfusion.com/react/demos/src/color-picker/images/pen.png')
    no-repeat;
  display: inline-block;
  height: 80px;
  margin: 10px 0;
  min-width: 300px;
  max-width: 600px;
  background-color: #008000;
}

.e-input-group:not(.e-float-icon-left), .e-input-group.e-success:not(.e-float-icon-left), .e-input-group.e-warning:not(.e-float-icon-left), .e-input-group.e-error:not(.e-float-icon-left), .e-input-group.e-control-wrapper:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left){
  border: none;
}

.loader {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.floating-button {
  display: flex;
  width: fit-content;
  height: 50px; /* Aumentar la altura */
  padding: 0px 25px; /* Incrementar el padding */
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 6px 6px 0px 0px;
  border: 0px;
  cursor: pointer;
  font-size: 1em;
  bottom: 50%;
  gap: 10px;
  position: fixed;
  right: 0;
  transform: translate(40%, 0) rotate(-90deg);
  background: #1fb519; /* Azul oscuro */
  color: #fdfdfe; /* Blanco */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  z-index: 1000; /* Asegura que esté encima de otros elementos */
}

.floating-button:hover {
  background: #1d4ed8; /* Azul más claro al pasar el mouse */
}



/* Estilo para el fondo semitransparente */
.bg-half-transparent {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}


/* Contenedor principal para cada ítem */
.item-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem; /* Espaciado entre ítems */
  border: 1px solid #e2e8f0; /* Borde sutil */ 
  border-radius: 8px; /* Bordes redondeados */
  padding: 1rem;
  background-color: #f9f9f9; /* Fondo claro */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra ligera */
}

/* Etiqueta para los campos */
.item-container label {
  font-weight: bold;
  color: #4a4a4a;
  margin-bottom: 0.3rem;
  display: block;
}

/* Estilo para inputs y select */
.item-container select,
.item-container input {
  width: 100%; /* Asegura que ocupen todo el ancho disponible */
  padding: 0.7rem;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  margin-bottom: 1rem; /* Separación entre campos */
  box-sizing: border-box; /* Padding incluido en el ancho */
  font-size: 1rem;
}

/* Botón para eliminar */
.item-container .delete-button {
  background-color: #f87171; /* Color rojo */
  color: white;
  padding: 0.6rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  align-self: flex-start; /* Alinea el botón a la izquierda */
}

.item-container .delete-button:hover {
  background-color: #ef4444; /* Color más oscuro al pasar el mouse */
}

/* Botón para agregar nuevo ítem */
.add-item-button {
  display: block;
  width: 100%;
  background-color: #ffae00; /* Amarillo llamativo */
  color: white;
  padding: 0.8rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  text-align: center;
  margin-top: 1rem;
}

.add-item-button:hover {
  background-color: #e09e00; /* Amarillo más oscuro */
}


header {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

header .logo img {
  max-width: 150px;
}

.cotizacion-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  position: relative;
}

.yellow-bar-left,
.yellow-bar-right {
  height: 20px;
  background-color: #ffc107;
  flex-grow: 1;
}

.yellow-bar-left {
  margin-right: 10px;
}

.yellow-bar-right {
  margin-left: 10px;
}

.cotizacion-title {
  font-size: 36px;
  font-weight: bold;
  color: black;
  margin: 0;
  padding: 5px 10px;
  background-color: white;
  z-index: 1;
  position: relative;
  flex-shrink: 0;
}

.cliente-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
}

.left-info,
.right-info {
  flex: 1;
  min-width: 45%;
}


.status-select {
  padding: 5px;
  border-radius: 5px;
  border: none;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

/* Item table */
/* Estilos base para la tabla */

.items-table {
  table-layout: auto; /* Hace que las columnas respeten su ancho */
  width: 95%; /* Ocupa todo el ancho disponible */
  border-collapse: collapse; /* Bordes más limpios */
}

.items-table th,
.items-table td {
  white-space: nowrap; /* Mantener el texto en una sola línea */
  text-align: center; /* Centra el contenido de las celdas */
  padding: 8px; /* Agrega espacio interno a las celdas */
  font-size: 14px; /* Tamaño de fuente reducido */
  border: 1px solid #ddd; /* Bordes visibles para cada celda */
}

.items-table th {
  background-color: #333; /* Fondo oscuro para encabezados */
  color: #fff; /* Texto blanco para contraste */
}

.items-table td:nth-child(2) { /* Aplica solo a la columna 'Item' */
  max-width: 150px; /* Limita el ancho de la columna */
  word-wrap: break-word; /* Rompe el texto si es muy largo */
  white-space: normal; /* Permite que el texto se ajuste en varias líneas */
}

.items-table td:first-child, /* Columna "Nº" */
.items-table th:first-child {
  width: 40px; /* Define un ancho fijo o ajustado */
  max-width: 50px; /* Evita que exceda este ancho */
  text-align: center; /* Centra el contenido */
  overflow: hidden; /* Oculta contenido que exceda el ancho */
  white-space: nowrap; /* Evita que el texto se divida en varias líneas */
  text-overflow: ellipsis; /* Agrega "..." si es demasiado largo */
}
.items-table td.min-width { /* Clase para la columna con ancho mínimo */
  white-space: nowrap; /* Mantiene el texto en una sola línea */
  text-align: left; /* Alinea el texto a la izquierda */
}

/* Responsivo */

/* Estilo específico para pantallas pequeñas */
@media (max-width: 768px) {
  .responsive-div {
    height: auto; /* Permite que crezca según el contenido */
    width: 100%; /* Ocupar todo el ancho */
    padding: 12px;
    border-left: none; /* Elimina el borde */
    box-shadow: none; /* Sin sombras */
  }

  /* Ajustes para botones y texto dentro del modal */
  .responsive-div button {
    font-size: 1rem; /* Tamaño relativo para mejor escalado */
    padding: 10px 16px;
  }

  .responsive-div h1, .responsive-div p {
    font-size: 1rem; /* Tamaño relativo para mejor escalado */
    line-height: 1.5; /* Espaciado para facilitar la lectura */
  }
}

/* Ajuste para pantallas medianas */
@media (max-width: 1280px) {
  .responsive-div {
    max-width: 100%; /* Ocupar todo el ancho */
  }
}

@media (min-width: 768px) {
  .item-container {
    max-width: 60%; /* Para pantallas grandes, limitar el ancho */
  }
}

@media (min-width: 1024px) {
  .item-container {
    max-width: 50%; /* Reducir aún más el ancho en pantallas grandes */
  }
}

@media  screen and (max-width:800px) {
  .sidebar{
    z-index: 10000000;
  }
}

/* Estilo responsivo para pantallas pequeñas */
@media (max-width: 768px) {
  .items-table th:nth-child(1), /* Primera columna (Nº) */
  .items-table td:nth-child(1){
    display: none; /* Ocultar columnas menos relevantes */
  }

  /* Asegurar que las filas especiales como IVA se ajusten */
  .items-table tr td:last-child {
    display: table-cell; /* Asegura que se comporten como celdas normales */
    width: auto; /* Permite que la celda ajuste su ancho al contenido */
    text-align: right; /* Alinea los valores numéricos a la derecha */
  }
}

/* Ajustes para pantallas de PC */
@media (min-width: 768px) { /* Tablets y pantallas más grandes */
  .floating-button {
    height: 50px; /* Más alto */
    padding: 0px 30px; /* Más ancho */
    font-size: 1.2em; /* Texto más grande */
    border-radius: 12px; /* Más redondeado */
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.3); /* Sombra más intensa */
  }
}

/* Ajustes para pantallas muy grandes (PC grandes o monitores) */
@media (min-width: 1024px) {
  .floating-button {
    height: 70px; /* Aún más alto */
    padding: 0px 40px; /* Más ancho */
    font-size: 1.4em; /* Texto aún más grande */
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.4); /* Sombra más destacada */
  }
}

@media (max-width: 768px) {
  .cliente-info {
    display: flex;
    justify-content: space-between; /* Mantener columnas separadas */
    align-items: flex-start; /* Alinear las columnas al inicio */
  }

  /* Específicamente para el email */
  .left-info p:nth-child(4) { /* El cuarto párrafo corresponde al email */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%; /* Limitar el ancho al de la columna */
  }
}

.copy-button {
  background: none; /* Sin fondo */
  border: none; /* Sin borde */
  margin-left: 2px; /* Separación del texto */
  cursor: pointer; /* Cambiar cursor al pasar el mouse */
  font-size: 1rem; /* Ajustar tamaño del icono */
  color: #555; /* Color del botón */
}

.copy-button:hover {
  color: #1d4ed8; /* Cambiar color al pasar el mouse */
}

.email-container {
  display: flex; /* Alinear texto e ícono horizontalmente */
  align-items: center; /* Centra verticalmente el texto e ícono */
  gap: 8px; /* Espacio entre texto e ícono */
  max-width: 100%; /* Ocupa todo el ancho disponible */
  overflow: hidden; /* Oculta desbordamiento */
}

.email-text {
  flex: 1; /* Permite que el texto ocupe el espacio restante */
  overflow: hidden; /* Oculta texto desbordado */
  text-overflow: ellipsis; /* Agrega "..." si el texto es demasiado largo */
  white-space: nowrap; /* Mantiene el texto en una sola línea */
}

.copy-button {
  flex-shrink: 0; /* Evita que el ícono se reduzca */
  background: none; /* Sin fondo */
  border: none; /* Sin borde */
  cursor: pointer; /* Cambia el cursor al pasar el mouse */
  font-size: 1rem; /* Ajusta el tamaño del ícono */
  color: #555; /* Color del ícono */
}

.copy-button:hover {
  color: #1d4ed8; /* Cambia el color del ícono al pasar el mouse */
}



/* Estilo responsivo para pantallas pequeñas */
@media (max-width: 768px) {
  .email-container {
    font-size: 0.85rem; /* Reducir el tamaño de la letra en pantallas pequeñas */
  }

  .email-text {
    font-size: 0.85rem; /* Ajusta el texto del email */
  }

  .copy-button {
    font-size: 0.9rem; /* Ajusta el tamaño del ícono */
  }
}

.direccion-text {
  word-wrap: break-word; /* Permite romper palabras largas si es necesario */
  white-space: normal; /* Permite que el texto ocupe varias líneas */
  overflow-wrap: break-word; /* Asegura compatibilidad adicional */
  margin-bottom: 8px; /* Espaciado debajo del párrafo */
}

@media (max-width: 768px) {
  .direccion-text {
    font-size: 0.9rem; /* Reduce el tamaño del texto en pantallas pequeñas */
  }
}

