/* Estilos generales */
.logoImage {
  display: block;
  margin: 0 auto;
  width: 50%;
  max-width: 400px;
  height: auto;
  margin-bottom: 20px;
  filter: invert(100%); /* Invierte los colores del logo */
}

.loginContainer {
  background-color: rgba(255, 255, 255, 0.9); /* Fondo blanco semitransparente */
  padding: 2rem;
  border-radius: 10px; /* Bordes redondeados */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* Sombra suave */
  max-width: 400px;
  width: 100%;
  margin: 20px;
  z-index: 1; /* Para que el formulario esté encima del fondo */
  position: relative;
}


.loginPage {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 50px;
}

.loginPage::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Capa oscura con transparencia */
  z-index: 0; /* Mantiene la capa por debajo del contenido */
}

/* Encabezado del login */
.loginContainer h2 {
  font-size: 24px;
  margin-bottom: 1rem;
  color: #333;
  text-align: center;
}

/* Estilo de los inputs */
.inputField {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

/* Botón de inicio de sesión */
.submitButton {
  background-color: #007bff; /* Color azul */
  color: white;
  padding: 0.75rem;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
}

.submitButton:hover {
  background-color: #0056b3; /* Azul más oscuro al pasar el mouse */
}

/* Texto de error */
.errorMessage {
  color: red;
  font-size: 14px;
  text-align: center;
}

/* Link de registro y olvidé contraseña */
.loginLink {
  color: #007bff;
  text-decoration: none;
  font-size: 14px;
}

.loginLink:hover {
  text-decoration: underline;
}

/* Ajuste para móviles */
@media (max-width: 768px) {
  .loginContainer {
    margin: 0 20px;
    padding: 1.5rem;
  }
}
